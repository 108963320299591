const langHash = {
  home: {
    mne: "Početna",
    en: "Home",
  },
  "about-us": {
    mne: "O nama",
    en: "About us",
  },
  "news-all": {
    mne: "Novosti",
    en: "News",
  },
  projects: {
    mne: "Projekti",
    en: "Projects",
  },
  library: {
    mne: "Biblioteka",
    en: "Library",
  },
  partners: {
    mne: "Partneri",
    en: "Partners",
  },
  teamIMI: {
    mne: "Tim I MI Boke",
    en: "Team IYDB",
  },
  donators: {
    mne: "Donatori i sponzori",
    en: "Donators and sponsors",
  },
  "contact-us": {
    mne: "Kontaktiraj nas",
    en: "Contact us",
  },
  materials: {
    mne: "Korisni materijali",
    en: "Useful materials",
  },
  "inclusive-community": {
    mne: "Bokeški inkluzivni đir",
    en: "Boka bay inclusive stroll",
  },
  donate: {
    mne: "Doniraj",
    en: "Donate us",
  },
  membership: {
    mne: "Postani član/ica",
    en: "Become member",
  },
  about: {
    mne: "O nama",
    en: "About us",
  },
  about1: {
    mne: `Inicijativa mladih s invaliditetom Boke (I MI Boke) je neprofitna, nevladina
        organizacija, osnovana 11. aprila 2019. godine u Kotoru, a registrovana u maju iste godine.
        U I MI Boke se dobrovoljno udružuju djeca, mladi i odrasli sa svim vrstama invalidnosti,
        kao i ostali građani/ke zainteresovani/e za unaprjeđenje položaja ovih osoba u društvu. Rad I
        MI Boke se zasniva na pristupu invaliditetu baziranom na ljudskim pravima osoba s
        invalidnošću.`,
    en: `The Initiative of Youth with Disabilities of Boka (IYDB) is a non-profit, non-
        governmental organization, founded on April 11. 2019. in Kotor, and registered in May of the
        same year. Children, young people and adults with all types of disablement, as well as other
        citizens interested in improving the position of these people in society, voluntarily associate
        in the IYDB. The work of the IYDB is based on the human rights approach to disability.`,
  },
  about2: {
    mne: `Misija I MI Boke je: „Inicijativa mladih s invaliditetom Boke promoviše i doprinosi
        ravnopravnom položaju djece, mladih i odraslih s invalidnošću u društvu kroz inicijative i
        aktivnosti za uspostavljanje zakonskog, kulturnog, političkog, ekonomskog i socijalnog
        okvira koji to omogućava.”, a vizija je: „Društvo u kome sigurno žive osobe s invalidnošću
        uživajući u samostalnosti, jednakosti i dostojanstvu.“.`,
    en: `The mission of the IYDB is: “The Initiative of Youth with Disabilities of Boka promotes
        and contributes to equal status of children, youth and adults with disablement in society,
        through initiatives and activities for establishment of legal, cultural, political, economic and
        social framework which enables it.”, while its vision is: “The society where persons with
        disablement live securely, enjoying the independence, equality and dignity.”.`,
  },
  about3: {
    mne: `Ciljevi I MI Boke su:`,
    en: "The goals of the IYDB are:",
  },
  aboutli1: {
    mne: `Uključiti djecu, mlade i odrasle s invalidnošću u sve segmente društvenog i
        ekonomskog života, a naročito u oblasti obrazovanja, zapošljavanja, zdravstva,
        socijalne i dječje zaštite, informisanja, političkog i javnog djelovanja, kulture, sporta,
        aktivnosti u slobodno vrijeme i dr;`,
    en: `To include children, youth and adults with disablement in all segments of social and
        economic life, especially in the field of education, employment, health, social and
        child protection, information, political and public activities, culture, sports, leisure
        activities, etc.;`,
  },
  aboutli2: {
    mne: `Podržati i osposobiti djecu, mlade i odrasle s invalidnošću da u potpunosti i na
        jednakim osnovama s drugima uživaju svoja prava i slobode, iskoriste svoje
        potencijale i prepoznaju i zadovolje svoje potrebe;`,
    en: `To support and empower children, youth and adults with disablement to fully and on
        an equal basis with others enjoy their rights and freedoms, utilize their potentials and
        recognize and meet their needs;`,
  },
  aboutli3: {
    mne: `Stvoriti uslove za samostalni život djece, mladih i odraslih s invalidnošću;`,
    en: `To create conditions for the independent living of children, youth and adults with
        disablement;`,
  },
  aboutli4: {
    mne: `Dostići zadovoljavajući nivo svijesti javnosti o ljudskim pravima, jednakosti,
        potrebama i potencijalima djece, mladih i odraslih s invalidnošću;`,
    en: `To achieve a satisfactory level of public awareness of human rights, equality, needs
        and potentials of children, youth and adults with disablement;`,
  },
  aboutli5: {
    mne: `Omogućiti puno i efektivno uživanje prava i sloboda djece, žena, Roma i drugih
        višestruko diskriminisanih osoba s invalidnošću, na jednakim osnovama s drugima.`,
    en: `To enable the full and effective enjoyment of the rights and freedoms of children,
        women, Roma and other multi-discriminated persons with disablement on an equal
        basis with others.`,
  },
  about4: {
    mne: `I MI Boke je članica: Evropske mreže za samostalni život (European Network on Independent
      Living - ENIL), nezavisne svjetske organizacije osoba sa psihosocijalnim invaliditetom TCI
      (Transforming Communities for Inclusion), Koordinacionog mehanizma organizacija civilnog
      društva konferencije država potpisnica Konvencije UN-a o pravima osoba s invaliditetom, Mreže
      za nadzor rodno odgovornog budžeta Zapadnog Balkana i Moldavije (Gender Budget Watchdog
      Network), Nezavisnog mehanizma za promociju, zaštitu i praćenje primjene Konvencije UN-a o
      pravima osoba s invaliditetom u Crnoj Gori i Savjeta za prava osoba s invaliditetom Opštine
      Kotor.`,
    en: `The IYDB is a member of the European Network on Independent Living (ENIL), the independent
      world organisation of people with psychosocial disabilities “Transforming Communities for
      Inclusion” (TCI), the Civil Society Coordination Mechanism for the Conference of States Parties
      to the UN Convention on the Rights of Persons with Disabilities, the Gender Budget Watchdog
      Network of the Western Balkans and Moldova, Independent Mechanism for the Promotion,
      Protection and Monitoring of the Implementation of the UN Convention on the Rights of Persons
      with Disabilities in Montenegro and the Council for the Rights of Persons with Disabilities of the
      Municipality of Kotor.`,
  },
  disclaimer: {
    mne: `Ovaj web sajt‭‭ je‬‬ kreiran ‭i održava‬n uz finansijsku  ‭podršku‭ ‭ ‬Evropske unije‭. ‭Nj‭egov‬‬‬ ‭ sadržaj ‭ ‬je ‬‬‬ isključiva odgovornost NVO „Inicijativa mladih s invaliditetom Boke” (I MI Boke)‭‭‭ i ‭ne odražava nužno stavove Evropske unije‭‭.*`,
    en: `This website was created and is maintained with the financial support of the European Union. Its content is the sole responsibility of the NGO 'Initiative of Youth with Disabilities of Boka' (IYDB) and does not necessarily reflect the views of the European Union.*`,
  },
  team1: {
    mne: `Inicijativa mladih s invaliditetom Boke (I MI Boke) trenutno ima dvoje zaposlenih na puno
    radno vrijeme i troje zaposlenih koji rade nepuno radno vrijeme. I MI Boke ima i 8 stalnih
    saradnika/ca koji/e su angažovani/e na realizaciji projekata, među kojima su kako osobe s
    invaliditetom (OSI) aktivne u borbi za svoja i prava drugih pripadnika/ca ove grupe, tako i osobe
    bez invaliditeta sa stručnim znanjima i dugogodišnjim iskustvom u radu s OSI.`,
    en: `The Initiative of Youth with Disabilities of Boka (IYDB) currently has two full-time and three
    part-time employees. The IYDB also has 8 permanent associates engaged in project
    implementation, including both people with disabilities (PWDs), who are active in fighting for
    their own and other's disability rights, and persons without disabilities with professional
    knowledge and many years of experience in working with PWDs.`,
  },
  team2: {
    mne: `U I MI Boke poslove vrše Izvršni/a direktor/ka, Služba za opšte poslove i Stručni tim.`,
    en: ``,
  },
  team3: {
    mne: `Izvršni/a direktor/ka koordiniše i odgovoran/a je za rad Službe za opšte poslove i učestvuje u
        radu Stručnog tima, po potrebi.`,
    en: `The tasks in the IYDB are performed by the Executive Director, the General Affairs Service and
        the Expert Team.`,
  },
  team4: {
    mne: `Služba za opšte poslove obavlja poslove u vezi sa administrativno finansijskim poslovanjem i sa
        strateškim planiranjem, pisanjem i koordinacijom projekata i predstavljanjem I MI Boke u
        javnosti.`,
    en: `The Executive Director coordinates and is responsible for the work of the General Affairs
        Service and participates in the work of the Expert Team, when needed.`,
  },
  team5: {
    mne: "Službu za opšte poslove čine:",
    en: "The General Affairs Service consists of:",
  },
  team6: {
    mne: `Stručni tim obavlja poslove u vezi sa radom na konkretnim predmetima, učestvuje u strateškom i
        periodičnom planiranju, pisanju i sprovođenju projekata, praćenju potreba ciljne grupe I MI
        Boke.`,
    en: `The expert team performs work related to specific matters, participates in strategic and periodic
        planning, writing and implementation of projects, monitoring the needs of the target group of the
        IYDB.`,
  },
  team7: {
    mne: `Stručni tim čine:`,
    en: `The expert team consists of:`,
  },
  internal1: {
    mne: `Organi Inicijative mladih s invaliditetom Boke (I MI Boke) su: Skupština, Savjet i
        Izvršni/a direktor/ka.`,
    en: `The bodies of the Initiative of Youth with Disabilities of Boka (IYDB) are: the Assembly,
        the Council and the Executive Director.`,
  },
  internal11: {
    mne: `Skupština je najviši organ upravljanja I MI Boke. Najmanje 50% +1 (pedeset
            posto plus jedan) članova/ica Skupštine mora biti iz reda osoba s invalidnošću. Mandat
            Skupštine traje 4 godine.`,
    en: `The Assembly is the highest governing body of the IYDB. At least 50% +1 (fifty
            percent plus one) of the Assembly members must be from the group of persons with
            disablement. The mandate of the Assembly lasts 4 years.`,
  },
  internal2: {
    mne: `Nadležnosti Skupštine I MI Boke su:`,
    en: `The competencies of the Assembly of the IYDB involve:`,
  },
  internalli1: {
    mne: `usvaja Statut i njegove izmjene i dopune, 2/3 većinom članova/ica Skupštine`,
    en: `adopting the Statute of the IYDB, as well as its amendments, provided that 2/3 of the
        majority of the Assembly members vote in favour of it`,
  },
  internalli2: {
    mne: `bira i razrješava Predsjednika/cu Skupštine, Savjet I MI Boke i Izvšnog/u direktora/ku
        nadpolovičnom većinom članova/ica Skupštine`,
    en: `electing and dismissing the President of the Assembly, the Council of the IYDB and
        the Executive Director by a simple majority of the Assembly members`,
  },
  internalli3: {
    mne: `birа i rаzrješаvа druge organe I MI Boke;
        odlučuje o isključenju članova/ica`,
    en: `electing and dismissing other bodies of the IYDB, deciding on the withdrawal of members`,
  },
  internalli4: {
    mne: `daje saglasnost za otuđivanje imovine čija je vrijednost veća od 10.000€ (deset
            hiljada eura)`,
    en: `giving consent for transfer of property valued at more than € 10,000 (ten thousand
            euros)`,
  },
  internalli5: {
    mne: `usvaja Strateški plan na predlog Savjeta`,
    en: `adopting the Strategic Plan of the IYDB upon the proposal of the Council`,
  },
  internalli6: {
    mne: `usvaja plan rada za narednu godinu i izvještaj o radu za prethodnu kalendarsku godinu
        na predlog Savjeta`,
    en: `adopting the Work Plan for the upcoming year and the Report on Work for the
        previous calendar year at the proposal of the Council`,
  },
  internalli7: {
    mne: `usvаjа godišnji finansijski izvještaj na predlog Savjeta`,
    en: `adopting the Annual Financial Report at the proposal of the Council`,
  },
  internalli8: {
    mne: `usvaja Akt o unutrašnjoj organizaciji i sistematizaciji radnih mjesta i
        angažovanju saradnika`,
    en: `adopting the Act on Internal Organization and Systematisation of Working Places
        and Hiring of Associates`,
  },
  internalli9: {
    mne: `odlučuje o promjeni cilja i djelatnosti, prestаnku rаdа i raspodjeli preostale imovine I
        MI Boke`,
    en: `deciding on the change of goals and activities, termination of work and disposal and
        distribution of the remained property of the IYDB;`,
  },
  internalli10: {
    mne: `odlučuje i o drugim pitanjima za koja Statutom nije utvrđena nadležnost drugih
        organa I MI Boke`,
    en: `deciding on other issues, for which the competence of other bodies of the IYDB has
        not been stipulated by the Statute.`,
  },
  internal3: {
    mne: `Savjet je savjetodavni organ I MI Boke. Savjet čine tri opunomoćena člana/ice
        Skupštine, dok I MI Boke ima do 100 članova/ica. Kada I MI Boke dostigne broj
        članova/ica veći od 100 onda se bira pet opunomoćenih članova/ica Skupštine. Svi/e
        članovi/ice Savjeta moraju biti iz reda osoba s invalidnošću. Mandat Savjeta traje 4 godine.`,
    en: `The Council is the advisory body of the IYDB. The Council consists of three authorized
        members of the Assembly, while the number of members in the IYDB does not exceed 100.
        When the number of members in the IYDB exceeds 100, five authorized members
        are to be elected. All Council members shall be from the group of persons with disablement.
        The mandate of the Council lasts 4 years.`,
  },
  internal4: {
    mne: `Nadležnosti Savjeta I MI Boke su:`,
    en: `The competencies of the Council of the IYDB are to:`,
  },
  internalsli1: {
    mne: `predlaže izmjene i dopune Statuta`,
    en: `propose amendments to the Statute`,
  },
  internalsli2: {
    mne: `bira i razrješava nadpolovičnom većinom Predsjednika/cu Savjeta;
        predlaže kandidate/kinje za izbor Izvršnog/e direktora/ke`,
    en: `elect and dismiss the President of the Council by simple majority;
        propose candidates for election of the Executive Director`,
  },
  internalsli3: {
    mne: `daje saglasnost za otuđivanje imovine čija je vrijednost od 1.000 do 10.000€
        (hiljadu do deset hiljada eura)`,
    en: `give consent for the transfer of property with a value of € 1,000 to € 10,000 (one
            thousand to ten thousand euros)`,
  },
  internalsli4: {
    mne: `predlaže Strateški plan`,
    en: `propose the Strategic Plan of the IYDB`,
  },
  internalsli5: {
    mne: `predlaže plan rada za narednu godinu i izvještaj o radu za prethodnu
        kalendarsku godinu`,
    en: `propose Work Plan for the upcoming year and a Report on Work for the previous
        calendar year`,
  },
  internalsli6: {
    mne: `usvaja plan rada i izvještaj o radu za svaki
        kvartal, predlaže godišnji finansijski izvještaj`,
    en: `adopt the Work Plan and the Report on Work for each quarter, propose Annual Financial Report;`,
  },
  internalsli7: {
    mne: `odlučuje po zahtjevima za učlanjenje u I MI Boke`,
    en: `decide on the applications for membership in the IYDB`,
  },
  internalsli8: {
    mne: `odlučuje o udruživanju u saveze i druge oblike povezivanja,
        daje saglasnost na ugovore o radu i angažovanju saradnika`,
    en: `decide on joining alliances of the IYDB and other forms of association,
        give approval to employment contracts and hiring of associates`,
  },
  internalsli9: {
    mne: `daje saglasnost za prijedloge projekata u vrijednosti preko 10.000€ (deset
            hiljada eura) kojima se I MI Boke prijavljuje na konkurse`,
    en: `give approval to project proposals valued at more than € 10,000 (ten thousand euros)
        which the IYDB undertakes to apply for competitions`,
  },
  internal5: {
    mne: `Izvršni/a direktor/ka je lice koje zastupa i predstavlja I MI Boke. Izvršni/a direktor/ka
        se bira odlukom Skupštine, na mandat od 4 godine. Izvršni/a direktor/ka mora biti osoba s
        invalidnošću.`,
    en: `The Executive Director is a person who represents and acts on behalf of the IYDB.
        The Executive Director is elected on the basis of the decision of the Assembly of the IYDB
        for a four-year term-office. The Executive Director shall be a person with disablement.`,
  },
  internal6: {
    mne: `Izvršni/a direktor/ka:`,
    en: `The Executive Director:`,
  },
  internaldli1: {
    mne: `zaključuje ugovore i preduzima druge pravne radnje u ime i za račun I MI Boke`,
    en: `concludes contracts and undertakes other legal actions in the name and on behalf of
        the IYDB`,
  },
  internaldli2: {
    mne: `odgovara za zakonitost rada`,
    en: `is responsible for the legality of the work`,
  },
  internaldli3: {
    mne: `vodi poslove I MI Boke saglasno odlukama Skupštine i Savjeta`,
    en: `manages the affairs of the IYDB in accordance with the decisions of the Assembly
        and the Council`,
  },
  internaldli4: {
    mne: `priprema predlog godišnjeg izvještaja o radu i finansijskog izvještaja i druge
        materijale za sjednice Savjeta i Skupštine`,
    en: `prepares the proposal of the Annual Report on Work and the Financial Report and
        other materials for sessions of the Council and the Assembly`,
  },
  internaldli5: {
    mne: `je odgovoran/a za menadžment i rukovođenje I MI Boke`,
    en: `is responsible for the management and administration of the IYDB`,
  },
  internaldli6: {
    mne: `predstavlja i zastupa I MI Boke`,
    en: `acts on behalf of and represents the IYDB`,
  },
  internaldli7: {
    mne: `obavlja i druge poslove u skladu sa Zakonom, Statutom i drugim aktima I MI
        Boke`,
    en: `performs other tasks in accordance with the Law, the Statute and other acts of the IYDB`,
  },
  founders1: {
    mne: `Inicijativa mladih s invaliditetom Boke (I MI Boke) je osnovana od strane troje mladih
        Kotorana/ki s invaliditetom, koji/e imaju dugogodišnje iskustvo u pokretu za prava osoba
        s invaliditetom (OSI):`,
    en: `The Initiative of Youth with Disabilities of Boka (IYDB) was founded by three young
        persons with disabilities from Kotor, who have many years of experience in the
        movement for the rights of persons with disabilities (PWDs):`,
  },
  founders2: {
    mne: `Oni/e su svoje iskustvo i znanje odlučili/e da iskoriste za unapređenje položaja OSI u
        svom gradu, ali i šire, kroz inovativan pristup.`,
    en: `They decided to use their experience and knowledge to improve the position of PWDs in
        their city, but also beyond, through an innovative approach.`,
  },
  founders3: {
    mne: `Osnivačice I MI Boke su bile članice neformalne grupe "Za ravnopravnost žena s
        invaliditetom", koja je sprovel projekat "Akcija protiv nasilja i eksploatacije žena s
        invaliditetom", uz podršku Trag fondacije . U okviru ovog projekta u aprilu 2019. godine
        učestvovale su u "Obuci za rad na SOS telefonu za žene s invaliditetom s iskustvom
        nasilja i diskriminacije" u Beogradu. Obuku je organizovala NVO „…Iz kruga –
        Beograd”, partner na projektu. Na ovoj Obuci su usvojile mnogo novih znanja i vještina,
        koje su odlučile da primjene u svom radu, u okviru nove NVO, koja će se velikim
        dijelom baviti problemima žena s invaliditetom. Iako su i same žene s invaliditetom, ova
        Obuka im je pružila mogućnost da prošire svoje vidike u pogledu prepoznavanja nasilja i
        diskriminacije sa kojima se susrijeću u svakodnevnom životu. Tu promjenu su odlučile
        da, s Andrijom Samardžićem prenesu i na druge OSI u Crnoj Gori.`,
    en: `Female founders of the IYDB were members of the non-formal group "For Equality of
        Women with Disabilities", which implemented the project "Action Against Violence and
        Exploitation of Women with Disabilities", with the support of the Trag Foundation . As
        part of this project, they participated in the "Training for Work on the SOS Hotline for
        Women with Disabilities with Experience of Violence and Discrimination" in Belgrade in
        April 2019. The training was organized by the NGO "… Out of Circle - Belgrade", the
        partner in the project. At this Training, they adopted a lot of new knowledge and skills,
        which they decided to apply in their work, within the new NGO, which will largely deal
        with the problems of women with disabilities. Although they are women with disabilities
        themselves, this Training provided them with the opportunity to broaden their horizons in
        terms of recognizing the violence and discrimination they face in their daily lives. They
        decided to transfer this change, together with Andrija Samardžić, to other PWDs in
        Montenegro.`,
  },
};

function translate(key, lang) {
  return langHash[key][lang];
}

export default translate;
