import React from "react";
import { Link } from "gatsby";
import {
  container,
  circle,
  item,
  circleRose,
  itemRose,
} from "./NavItem.module.scss";
import useLang from "../../contexts/Language/LanguageContext";
import translate from "../../utils/lang/langHash";
import TextTransition, { presets } from "react-text-transition";
import useIsMobile from "../../utils/is-mobile/isMobile";

function NavItem({ name, to }) {
  const lang = useLang();
  const isMobile = useIsMobile();
  return (
    <div className={container}>
      <span
        className={
          to === "/materials" || to === "/news" || to === "/donate"
            ? circleRose
            : circle
        }
      />
      <Link
        activeStyle={{
          color:
            to === "/materials" || to === "/news" || to === "/donate"
              ? "rgb(255, 93, 143)"
              : "#00adee",
        }}
        to={to}
        className={
          to === "/materials" || to === "/news" || to === "/donate"
            ? itemRose
            : item
        }
      >
        <TextTransition
          text={translate(name, lang)}
          springConfig={presets.stiff}
          noOverflow
        />
      </Link>
    </div>
  );
}

export default NavItem;
